// src/Routes.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes as ReactRoutes } from 'react-router-dom';
import Layout from './components/Layout';
import Converter from './pages/Converter';
// import Home from './pages/Home';

const AppRoutes: React.FC = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Converter />} />
      {/* Add more routes as needed */}
    </ReactRoutes>
  );
};

const Routes: React.FC = () => {
  return (
    <Router>
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
};

export default Routes;
