// src/components/Layout.tsx
import React, { ReactNode } from 'react';
import { Container, Typography, AppBar, Toolbar, Button } from '@mui/material';
import { styled } from '@mui/system';

interface LayoutProps {
    children: ReactNode;
}

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
}));

const StyledAppBar = styled(AppBar)({
    marginBottom: '20px',
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div>
            <StyledAppBar position="static">
                <StyledToolbar>
                    <Typography variant="h6">WeConvert</Typography>
                    <div>
                        <Button color="inherit" href="/">Home</Button>
                        <Button color="inherit" href="/converter">Converter</Button>
                        <Button color="inherit" href="/sendmoney">Send Money</Button>
                        <Button color="inherit" href="/contact">Contact</Button>
                    </div>
                </StyledToolbar>
            </StyledAppBar>
            <StyledContainer>
                {children}
            </StyledContainer>
            <footer
                style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#f8f8f8',
                    textAlign: 'center',
                    padding: '10px',
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    &copy; 2023 We Trust Group. All rights reserved.
                </Typography>
            </footer>
        </div>
    );
};

export default Layout;
