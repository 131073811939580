import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, IconButton, InputLabel, Typography, TextField, MenuItem, Select, ListItemIcon, InputAdornment } from '@mui/material';
import Iconify from '../helpers/Iconify';
import { fetchCurrencyData } from '../utils/CurrencyUtils';
import { Autocomplete } from '@mui/material';
import ApiExchangeRates from '../services/ApiExchangeRates';
import Loading from '../components/Loading';
import { formatCurrency, formatDate } from '../helpers/tools';
const Converter = () => {
  const apiExchangeRates = new ApiExchangeRates();
  const [loading, setLoading] = useState(false)
  const [resultRate, setResultRate] = useState<any>(null)
  const [result, setResult] = useState<any>(null)
  const [formData, setFormData] = useState({
    amount: '',
    from: '',
    to: '',
  });
  const [currencyData, setCurrencyData] = useState(
    [
      {
        "currency": "USD",
        "name": "United States Dollar",
        "region": "North America",
        "country": "United States",
        "flag": "emojione-v1:flag-for-united-states"
      },
      {
        "currency": "CAD",
        "name": "Canadian Dollar",
        "region": "North America",
        "country": "Canada",
        "flag": "emojione-v1:flag-for-canada"
      },
      {
        "currency": "MXN",
        "name": "Mexican Peso",
        "region": "North America",
        "country": "Mexico",
        "flag": "emojione-v1:flag-for-mexico"
      },
      {
        "currency": "EUR",
        "name": "Euro",
        "region": "Europe",
        "country": null,
        "flag": "twemoji:flag-european-union"
      },
      {
        "currency": "GBP",
        "name": "British Pound Sterling",
        "region": "Europe",
        "country": "United Kingdom",
        "flag": "emojione-v1:flag-for-united-kingdom"
      },
      {
        "currency": "CHF",
        "name": "Swiss Franc",
        "region": "Europe",
        "country": "Switzerland",
        "flag": "emojione-v1:flag-for-switzerland"
      },
      {
        "currency": "SEK",
        "name": "Swedish Krona",
        "region": "Europe",
        "country": "Sweden",
        "flag": "emojione-v1:flag-for-sweden"
      },
      {
        "currency": "NOK",
        "name": "Norwegian Krone",
        "region": "Europe",
        "country": "Norway",
        "flag": "emojione-v1:flag-for-norway"
      },
      {
        "currency": "DKK",
        "name": "Danish Krone",
        "region": "Europe",
        "country": "Denmark",
        "flag": "emojione-v1:flag-for-denmark"
      },
      {
        "currency": "HUF",
        "name": "Hungarian Forint",
        "region": "Europe",
        "country": "Hungary",
        "flag": "emojione-v1:flag-for-hungary"
      },
      {
        "currency": "PLN",
        "name": "Polish Złoty",
        "region": "Europe",
        "country": "Poland",
        "flag": "emojione-v1:flag-for-poland"
      },
      {
        "currency": "HRK",
        "name": "Croatian Kuna",
        "region": "Europe",
        "country": "Croatia",
        "flag": "emojione-v1:flag-for-croatia"
      },
      {
        "currency": "BGN",
        "name": "Bulgarian Lev",
        "region": "Europe",
        "country": "Bulgaria",
        "flag": "emojione-v1:flag-for-bulgaria"
      },
      {
        "currency": "RON",
        "name": "Romanian Leu",
        "region": "Europe",
        "country": "Romania",
        "flag": "emojione-v1:flag-for-romania"
      },
      {
        "currency": "RUB",
        "name": "Russian Ruble",
        "region": "Europe",
        "country": "Russia",
        "flag": "emojione-v1:flag-for-russia"
      },
      {
        "currency": "TRY",
        "name": "Turkish Lira",
        "region": "Europe",
        "country": "Turkey",
        "flag": "emojione-v1:flag-for-turkey"
      },
      {
        "currency": "UAH",
        "name": "Ukrainian Hryvnia",
        "region": "Europe",
        "country": "Ukraine",
        "flag": "emojione-v1:flag-for-ukraine"
      },
      {
        "currency": "ISK",
        "name": "Icelandic Króna",
        "region": "Europe",
        "country": "Iceland",
        "flag": "emojione-v1:flag-for-iceland"
      },
      {
        "currency": "AED",
        "name": "United Arab Emirates Dirham",
        "region": "Middle East",
        "country": "United Arab Emirates",
        "flag": "emojione-v1:flag-for-united-arab-emirates"
      },
      {
        "currency": "QAR",
        "name": "Qatari Riyal",
        "region": "Middle East",
        "country": "Qatar",
        "flag": "emojione-v1:flag-for-qatar"
      },
      {
        "currency": "KWD",
        "name": "Kuwaiti Dinar",
        "region": "Middle East",
        "country": "Kuwait",
        "flag": "emojione-v1:flag-for-kuwait"
      },
      {
        "currency": "OMR",
        "name": "Omani Rial",
        "region": "Middle East",
        "country": "Oman",
        "flag": "emojione-v1:flag-for-oman"
      },
      {
        "currency": "BHD",
        "name": "Bahraini Dinar",
        "region": "Middle East",
        "country": "Bahrain",
        "flag": "emojione-v1:flag-for-bahrain"
      },
      {
        "currency": "JPY",
        "name": "Japanese Yen",
        "region": "Asia",
        "country": "Japan",
        "flag": "emojione-v1:flag-for-japan"
      },
      {
        "currency": "CNY",
        "name": "Chinese Yuan",
        "region": "Asia",
        "country": "China",
        "flag": "emojione-v1:flag-for-china"
      },
      {
        "currency": "INR",
        "name": "Indian Rupee",
        "region": "Asia",
        "country": "India",
        "flag": "emojione-v1:flag-for-india"
      },
      {
        "currency": "THB",
        "name": "Thai Baht",
        "region": "Asia",
        "country": "Thailand",
        "flag": "emojione-v1:flag-for-thailand"
      },
      {
        "currency": "IDR",
        "name": "Indonesian Rupiah",
        "region": "Asia",
        "country": "Indonesia",
        "flag": "emojione-v1:flag-for-indonesia"
      },
      {
        "currency": "MYR",
        "name": "Malaysian Ringgit",
        "region": "Asia",
        "country": "Malaysia",
        "flag": "emojione-v1:flag-for-malaysia"
      },
      {
        "currency": "PHP",
        "name": "Philippine Peso",
        "region": "Asia",
        "country": "Philippines",
        "flag": "emojione-v1:flag-for-philippines"
      },
      {
        "currency": "VND",
        "name": "Vietnamese Đồng",
        "region": "Asia",
        "country": "Vietnam",
        "flag": "emojione-v1:flag-for-vietnam"
      },
      {
        "currency": "PKR",
        "name": "Pakistani Rupee",
        "region": "Asia",
        "country": "Pakistan",
        "flag": "emojione-v1:flag-for-pakistan"
      },
      {
        "currency": "KRW",
        "name": "South Korean Won",
        "region": "Asia",
        "country": "South Korea",
        "flag": "emojione-v1:flag-for-south-korea"
      }
    ]

  );
  useEffect(() => {
    if (resultRate && formData.amount) {
      calcuration(formData.amount)
    }
  }, [resultRate])

  const calcuration = (amount?: string) => {
    setResult(parseFloat(amount || '1') * (resultRate?.conversion_rate || 1))
  }


  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await fetchCurrencyData();
  //     setCurrencyData(data);
  //   };

  //   fetchData();
  // }, []);
  const renderCurrencyOptions = () => {
    return currencyData.map((currency: any) => (
      <MenuItem key={currency.currency} value={currency.currency} disabled={formData.from == currency.currency || formData.to == currency.currency}>
        <ListItemIcon>
          <Iconify icon={currency.flag} />
        </ListItemIcon>
        {currency.currency} - {currency.name}
      </MenuItem>
    ));
  };

  const handleChange = (e: any) => {
    const target = e.target as HTMLInputElement;
    setFormData({ ...formData, [target.name || '']: target.value });
    if (target.name == 'from' || target.name == 'to') {
      setResultRate(null)
      setResult(null)
    } else {
      console.log('else', target.value);

      if (resultRate) {
        console.log('resultRate', resultRate);
        calcuration(target.value)
      }
    }
  };

  // useEffect(() => {

  // }, [formData])



  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form data submitted:', formData);
    if (formData.from && formData.to && formData.amount) {
      getRate(formData.from, formData.to)
    }
  };
  const onSwith = () => {
    const currentFrom = formData.from
    const currentTo = formData.to
    setFormData({ ...formData, from: currentTo, to: currentFrom })
    setResultRate(null)
    setResult(null)
  }

  const getRate = (base: string, to: string) => {
    setLoading(true)
    apiExchangeRates.pairExchangeRate(base, to).then(response => {
      console.log('response', response);
      if (response && response.result == "success") {
        setResultRate(response)
      } else {
        setResultRate(null)
      }
      setLoading(false)
    }).catch(() => {
      setResultRate(null)
      setLoading(false)
    })
  }

  return (
    <div>
      <Loading loading={loading} />
      <Card>
        <CardContent>
          <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
            <Iconify icon='bi:currency-exchange' sx={{ marginRight: '8px' }} />
            <div>Currency Converter</div>
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              {/* <Grid item xs={12}>
                <Typography variant="subtitle1" component="div" gutterBottom>
                  Amount
                </Typography>
              </Grid> */}
              <Grid item xs={3}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <TextField
                  type="number"
                  fullWidth
                  name="amount"
                  variant="outlined"
                  placeholder='0.00'
                  value={formData.amount}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{formData.from || ''}</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel htmlFor="from">From</InputLabel>
                <Select
                  fullWidth
                  label="From"
                  name="from"
                  value={formData.from}
                  onChange={(event: any) => handleChange(event)}
                  variant="outlined"
                >
                  {renderCurrencyOptions()}
                </Select>
                {/* <Autocomplete
                  fullWidth
                  options={currencyData}
                  getOptionLabel={(option) => option.currency + ' - ' + option.name}
                  value={currencyData.find((currency) => currency.currency === formData.from) || null}
                  onChange={(_, value) => handleChange({ target: { name: 'from', value: value?.currency || '' } })}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <ListItemIcon>
                        <Iconify icon={option.flag} />
                      </ListItemIcon>
                      {option.currency} - {option.name}
                    </li>
                  )}
                /> */}
              </Grid>
              <Grid item xs={1} sx={{ alignItems: 'center', display: 'flex' }}>
                <IconButton onClick={() => onSwith()}>
                  <Iconify icon="icon-park-outline:switch" />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <InputLabel htmlFor="to">To</InputLabel>
                <Select
                  fullWidth
                  label="To"
                  name="to"
                  value={formData.to}
                  onChange={(event: any) => handleChange(event)}
                  variant="outlined"
                >
                  {renderCurrencyOptions()}
                </Select>
              </Grid>
            </Grid>
            <div>
              <Typography sx={{ textAlign: 'center', marginTop: 2, alignItems: 'center' }}>
                <Button size='large' startIcon={<Iconify icon='material-symbols-light:next-week-outline' />} type="submit" variant="contained" color="primary" disabled={!formData.amount || !formData.from || !formData.to}>
                  Convert
                </Button>
                {/* <IconButton onClick={() => onSwith()} disabled={!formData.amount || !formData.from || !formData.to}>
                  <Iconify icon="icon-park-outline:switch" />
                  <div>Convert</div>
                </IconButton>
                <Typography variant="button">Save</Typography> */}
              </Typography>
            </div>
            <Typography component='div' sx={{ display: result ? '' : 'none', marginTop: 2 }}>
              <Typography component='div' variant='h5'>
                {formData.amount || 1} {currencyData.find(c => c.currency == formData.from)?.name} =
              </Typography>
              <Typography component='div' variant='h3' sx={{fontWeight:'bold'}}>
                {formatCurrency(result, 6)} {currencyData.find(c => c.currency == formData.to)?.name}
              </Typography>
              <Typography component='div' variant='h6'>
                {formData.amount || 1} {formData.from} = {resultRate?.conversion_rate || 1} {formData.to}
              </Typography>
              <Typography component='span'>
                Last updated {formatDate(resultRate?.time_last_update_utc ) }
              </Typography>
            </Typography>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Converter;
