// src/App.tsx
import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Routes from './Routes'; // Import the Routes component

const theme = createTheme();

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes /> {/* Render the Routes component */}
    </ThemeProvider>
  );
};

export default App;
