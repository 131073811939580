export const formatCurrency = (value: string | number, maxDigit?: number) => {
    if (!value) {
        return ''
    }
    // Parse value to ensure it's a number
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // Use toFixed to round to the specified number of decimal places
    const roundedValue = numericValue.toFixed(maxDigit);

    // Remove trailing zeros after the decimal point
    const formattedValue = parseFloat(roundedValue).toString();

    // Determine the length of the fractional part
    const fractionalLength = formattedValue.split('.')[1]?.length || 0;

    // Use toLocaleString for formatting
    return parseFloat(formattedValue).toLocaleString(undefined, {
        minimumFractionDigits: Math.min(maxDigit || 0, fractionalLength),
        maximumFractionDigits: fractionalLength, // Set to the length of the fractional part
    });
}

export const formatDate = (dateString: string, format: string = 'en-US') => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
    };
    const formattedDate = new Date(dateString).toLocaleString(format, options);

    return formattedDate
}