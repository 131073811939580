import { Icon } from '@iconify-icon/react';
import { Typography } from '@mui/material';
import React from 'react'

interface IconifyProps {
    icon: string,
    size?: string,
    sx?: any
}
const Iconify: React.FC<IconifyProps> = ({ icon, size, sx = {} }) => {
    return (
        <Typography sx={sx}>
            <Icon style={{ fontSize: size }} icon={icon} />
        </Typography>

    )
}

export default Iconify